import {useEffect, useState} from 'react';
import styled from 'styled-components';
import confetti from 'canvas-confetti';
import * as anchor from '@project-serum/anchor';
import {LAMPORTS_PER_SOL, PublicKey} from '@solana/web3.js';
import {useAnchorWallet} from '@solana/wallet-adapter-react';
import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {GatewayProvider} from '@civic/solana-gateway-react';
import Countdown from 'react-countdown';
import {Snackbar, Paper} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {ReactComponent as Twitter} from './img/social/Twitter.svg';
import {ReactComponent as Discord} from './img/social/Discord.svg';
import Marquee from 'react-fast-marquee';

import MainVideoWebm from './video/main-video.webm';
import MainVideoMp4 from './video/main-video.mp4';
import CossackGallery from './img/CossackGallery.webp';
import AboutBackgroundImage from './img/AboutBackground.webp';
import AboutCossacksImage from './img/AboutCossacks.webp';
import CommunityPassBackground from './img/CommunityPassBackground.webp';
import CommunityPassBoxImage from './img/CommunityPassBoxPNG.webp';
import TeamBackground from './img/Team.webp';
import Poposha from './img/team/poposha.webp';
import PoposhaHover from './img/team/hover/poposha.webp';
import Neverwin from './img/team/neverwin.webp';
import NeverwinHover from './img/team/hover/neverwin.webp';
import Dima from './img/team/3dima.webp';
import DimaHover from './img/team/hover/3dima.webp';
import Sonder from './img/team/sonder.webp';
import SonderHover from './img/team/hover/sonder.webp';
import Softcake from './img/team/softcake.webp';
import SoftcakeHover from './img/team/hover/softcake.webp';

import {ReactComponent as Stage1} from './img/roadmap/stage1.svg';
import {ReactComponent as Stage2} from './img/roadmap/stage2.svg';
import {ReactComponent as Stage3} from './img/roadmap/stage3.svg';
import {ReactComponent as Stage4} from './img/roadmap/stage4.svg';
import {ReactComponent as Stage5} from './img/roadmap/stage5.svg';
import {ReactComponent as Stage6} from './img/roadmap/stage6.svg';
import {ReactComponent as ArrowLeft} from './img/roadmap/ArrowLeft.svg';
import {ReactComponent as ArrowRight} from './img/roadmap/ArrowRight.svg';

import {ReactComponent as MagicEden} from './img/partners/magic-eden.svg';
import {ReactComponent as Solana} from './img/partners/solana.svg';
import {ReactComponent as OpenSea} from './img/partners/opensea.svg';
import {ReactComponent as MintyScore} from './img/partners/mintyscore.svg';

import Logo from './img/logo.png';

import {toDate, AlertState, getAtaForMint} from './utils';
import {MintButton} from './MintButton';

import {
    CandyMachine,
    awaitTransactionSignatureConfirmation,
    getCandyMachineState,
    mintOneToken,
    CANDY_MACHINE_PROGRAM,
} from './candy-machine';

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS
    ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString()
    : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME
    ? process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME.toString()
    : 'TOKEN';

const CommunityPassContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: black;
  background-image: url(${CommunityPassBackground});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  border-bottom: 2px solid var(--cyber-green);

  @media (min-width: 768px) {
    min-height: 100vh;
  }
`;

const Border = styled.div`
  border: 2px solid var(--cyber-green);
  border-top: 0;
`;

const Team = styled.div`
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: black;
  background-image: url(${TeamBackground});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
`;

const Image = styled.img`
  height: 60vh;
  cursor: pointer;
  border-bottom: 2px solid var(--cyber-green);
  background-color: black;

  @media (min-width: 768px) {
    height: 100vh;
  }
`;

const Partners = styled.div`
  background-color: black;
  padding: 3rem 0;

  @media (min-width: 768px) {
    padding: 4rem 0;
  }
`;

const PartnersRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 75%;
  }
`;

const PartnersCol = styled.div`
  cursor: pointer;
  flex: 1 1 30%;
  margin: 1rem;

  svg {
    max-width: 150px;
    opacity: 0.4;

    :hover {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    flex: 1 1 25%;
    margin: 0;

    svg {
      max-width: 150px;
    }
  }
`;


const PartnersTitle = styled.h3`
  margin: 0;
  color: var(--cyber-green);
  font-size: 1.5rem;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  text-decoration: none;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    font-size: 2rem;
    margin-bottom: 4rem;
  }
`;

const CommunityPassBox = styled.div`
  min-height: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;

  @media (min-width: 768px) {
    padding: 5rem 0;
    width: 65vw;
  }
`;

const CommunityPassBoxImageContainer = styled.img`
  width: 100%;
  order: 2;

  @media (min-width: 768px) {
    order: 1;
  }
`;

const CommunityPassBoxTextContainer = styled.div`
  padding: 2rem 2rem 3rem 2rem;
  box-sizing: border-box;
  order: 1;

  @media (min-width: 768px) {
    padding: 3rem;
    background-color: var(--black);
    border: 2px solid var(--cyber-green);
    order: 2;
  }
`;

const CommunityPassText = styled.p`
  padding: 0;
  margin: 0;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 20px;
    text-align: justify;
    text-align-last: center;
  }
`;

const CommunityPassTitle = styled.h2`
  margin: 0;
  color: var(--cyber-green);
  font-size: 1.5rem;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  text-decoration: none;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;

const TeamBox = styled.div`
  text-align: center;
  width: 100%;

  @media (min-width: 1200px) {
    width: 80vw;
    border: 2px solid var(--cyber-green);
    background: black;
  }
`;

const TeamMain = styled.div`
  padding: 2rem;

  @media (min-width: 1200px) {
    padding: 3rem;
  }
`;

const TeamTitle = styled.h3`
  margin: 0;
  color: var(--cyber-green);
  font-size: 1.5rem;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  text-decoration: none;
  padding: 0 0 20px;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const TeamDesc = styled.p`
  color: white;
  font-size: 1rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 65%;
    font-size: 20px;
    text-align: justify;
    text-align-last: center;
  }
`;

const TeamRow = styled.div`
  border-top: 2px solid var(--cyber-green);
  display: flex;
  flex-wrap: wrap;
`;

const TeamCol = styled.div`
  cursor: pointer;
  border-right: 2px solid var(--cyber-green);
  border-bottom: 2px solid var(--cyber-green);
  flex: 1 1 25%;
  background: black;

  @media (min-width: 992px) {
    flex: 1 1 18%;
  }

  @media (min-width: 1200px) {
    border-bottom: 0;

    :last-child {
      border-right: 0;
    }
  }
`;

const TeamImage = styled.img`
  width: 100%;
`;

const TeamP = styled.div`
  padding: 1rem 5px;

  @media (min-width: 768px) {
    padding: 1rem;
  }
`;

const TeamColName = styled.h4`
  color: var(--cyber-green);
  font-size: 1rem;
  font-family: var(--font-horizon);
  margin: 0;
  font-weight: bold !important;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const TeamColRole = styled.p`
  color: #fff9;
  font-size: 12px;
  margin: 0;
  font-weight: bold !important;

  @media (min-width: 1200px) {
    font-size: 20px;
  }
`;

const AboutContainer = styled.div`
  text-align: center;
  background-color: black;
  background-image: url(${AboutBackgroundImage});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  position: relative;
  border-bottom: 2px solid var(--cyber-green);

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
`;
const AboutCossacks = styled.img`
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    max-width: 85%;
  }
`;
const AboutTextContainer = styled.div`
  padding: 2rem;
  margin: 0 auto;
  padding-bottom: 40%;

  @media (min-width: 768px) {
    max-width: 70%;
    padding-bottom: 35%;
  }

  @media (min-width: 1200px) {
    max-width: 50%;
    padding-top: 4rem;
    padding-bottom: 25%;
  }
`;

const AboutTitle = styled.h1`
  color: var(--cyber-green);
  font-size: 2rem;
  text-transform: uppercase;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const AboutText = styled.p`
  color: var(--white);
  font-weight: bold;
  font-size: 17px;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 20px;
    text-align: justify;
    text-align-last: center;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  position: relative;
`;

const LogoImage = styled.img`
  max-height: 40px;
`;

const MainMintContainer = styled(WalletMultiButton)`
  width: 100% !important;
  height: 100% !important;
  color: var(--cyber-green);
  font-size: 3rem !important;
  box-shadow: none !important;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  transition: all 300ms ease-out !important;
  background-color: var(--black) !important;
  border-radius: 0 !important;
  display: inline;
  position: relative;
  line-height: initial;

  :hover {
    background-color: var(--cyber-green) !important;
    color: black !important;

    :after {
      display: none;
    }
  }

  :after {
    content: '1 sol';
    position: absolute;
    font-size: 10px;
    right: 0;
    top: 0;
    background: var(--cyber-green);
    color: black;
    padding: 10px;
  }
`;

const ButtonsContainer = styled.div`
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid var(--cyber-green);
  border-width: 3px 0;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    height: 5rem;
  }
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 5px solid var(--cyber-green);
  color: var(--cyber-green);
  background-color: var(--black);
  text-align: center;
  transition: all 200ms ease;

  :hover {
    background-color: var(--cyber-green);
    color: var(--black);

    svg {
      fill: var(--black);
    }
  }

  :last-child {
    border-right: 0;
  }

  svg {
    margin-right: 10px;
    fill: white;
  }
`;

const MobileMintButtonContainer = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid var(--cyber-green);
  color: var(--cyber-green);
  background-color: var(--black);
  text-align: center;

  transition: all 200ms ease;

  :hover {
    background-color: var(--cyber-green);
    color: var(--black);
  }

  :last-child {
    border-right: 0;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopMintButtonContainer = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-right: 5px solid var(--cyber-green);
  color: var(--cyber-green);
  background-color: var(--black);
  text-align: center;
  display: none;
  transition: all 200ms ease;

  :hover {
    background-color: var(--cyber-green);
    color: var(--black);
  }

  :last-child {
    border-right: 0;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

const BottomButtons = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const VideoContainer = styled.video`
  width: 100%;
  height: calc(100vh - 9rem - 6px);
  object-fit: cover;
  background-color: var(--black) !important;

  @media (min-width: 768px) {
    height: calc(100vh - 5rem - 6px);
  }
`;

const Card = styled(Paper)`
  display: inline-block;
  background-color: var(card-background-lighter-color) !important;
  margin: 5px;
  min-width: 40px;
  padding: 24px;

  h1 {
    margin: 0;
  }
`;

const RoadMapContainer = styled.div`
  background-color: var(--black) !important;
  border-bottom: 2px solid var(--cyber-green);
  padding-top: 2rem;

  @media (min-width: 992px) {
    padding: 4rem;
  }
`;
const RoadMapHeader = styled.h3`
  border-bottom: 2px solid var(--cyber-green);
  box-shadow: none !important;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  margin: 0;
  color: var(--cyber-green);
  font-size: 2rem !important;
  padding-bottom: 2rem;

  @media (min-width: 992px) {
    padding-bottom: 3rem;
  }
`;
const RoadMap = styled.div`
  display: flex;
  flex-direction: row;
  height: 60vh;
  justify-content: space-between;

  @media (min-width: 992px) {
    height: 85vh;
    border-bottom: 2px solid var(--cyber-green);
  }
`;
const StagesContainer = styled.div`
  flex-direction: column;
  height: 100%;
  width: 30%;
  display: none;
  @media (min-width: 992px) {
    display: flex ;
  }
`;

interface IStageContainer {
    active?: boolean;
}

const StageContainer = styled.div<IStageContainer>`
  border-bottom: 2px solid var(--cyber-green);
  height: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (props.active ? 'var(--cyber-green)' : 'var(--black)')};
  border-right: 2px solid var(--cyber-green);
  border-left: 2px solid var(--cyber-green);
  position: relative;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;

  :last-child {
    border-bottom: none;
  }
`;

const StageTitle = styled.div<IStageContainer>`
  color: ${(props) => (props.active ? 'var(--black)' : 'var(--cyber-green)')};
  font-size: 1.7rem !important;
  box-shadow: none !important;
  font-family: var(--font-horizon);
  font-weight: bold !important;
`;

const StageDone = styled.div<IStageContainer>`
  background: ${(props) => (props.active ? 'var(--black)' : 'var(--cyber-green)')};
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;

  svg {
    fill: ${(props) => (props.active ? 'var(--cyber-green)' : 'var(--black)')};
    width: 20px;
  }
`;

const ActiveStageTitle = styled.div`
  color: var(--cyber-green);
  font-size: 1.5rem !important;
  box-shadow: none !important;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  padding: 0;
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 992px) {
    padding: 0 2rem;
  }
`;

const ActiveStageSubtitleContainer = styled.div`
  display: flex;
`;
const ActiveStageSubTitle = styled.li`
  color: var(--white);
  font-weight: bold;
  font-size: 16px;
  text-align: start;
  margin-bottom: 1rem;

  ::marker {
    color: var(--cyber-green);
  }
`;

const ActiveStageContentContainer = styled.ul`
  width: 100%;
  padding: 2rem;
  overflow-y: scroll;
  list-style: square;
  margin: 0;

  @media (min-width: 992px) {
    padding: 2rem 4rem;
    width: 60%;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: black;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--cyber-green);
  }
`;


const Faq = styled.div`
  width: auto;
  background-color: var(--black) !important;
  padding: 4rem 0;
  border-bottom: 2px solid var(--cyber-green);
`;
const FaqHeader = styled.h3`
  margin: 0 0 3rem 0;
  box-shadow: none !important;
  font-family: var(--font-horizon);
  font-weight: bold !important;
  color: var(--cyber-green);
  font-size: 2rem !important;
`;

const FaqContainer = styled.div`
  border: 2px solid var(--cyber-green);
  width: 90%;
  margin: 0 auto;
  text-align: left;

  @media (min-width: 992px) {
    width: 60%;
  }
`;

const FaqLeft = styled.div`
  flex: 10;
  padding-left: 1rem;
  padding-top: 1.5rem;

  @media (min-width: 768px) {
    flex: 5;
  }

  svg {
    fill: var(--cyber-green);
    width: 18px;
  }
`;

const FaqRight = styled.div`
  flex: 90;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex: 95;
  }
`;

const FaqTitle = styled.h5`
  padding: 1.3rem 1.3rem 1.3rem 0;
  color: var(--cyber-green);
  margin: 0;
  font-size: 18px;
  font-family: var(--font-horizon);

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

const FaqItem = styled.div`
  border-bottom: 2px solid var(--cyber-green);
  cursor: pointer;
  display: flex;

  :last-child {
    border: 0;
  }
`;

const FaqContent = styled.p<IStageContainer>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  margin: 0;
  padding: 0 2rem 2rem 0;
  font-size: 1rem;
`;

const StageIcon = styled.div<IStageContainer>`
  margin-right: 2vh;

  svg {
    fill: ${(props) => (props.active ? 'var(--black)' : 'var(--cyber-green)')};
  }
`;

const RoadMapMobileButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--cyber-green);
  @media (min-width: 992px) {
    display: none;
  }
`;

const RoadMapButton = styled.button`
  cursor: pointer;
  padding: 1.5rem 1rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: var(--black);

  :first-child {
    border-right: 2px solid var(--cyber-green);
  }
`;

export interface HomeProps {
    candyMachineId: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    txTimeout: number;
    rpcHost: string;
}

const Home = (props: HomeProps) => {
    const [balance, setBalance] = useState<number>();
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
    const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
    const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>('');
    const [itemsAvailable, setItemsAvailable] = useState(0);
    const [itemsRedeemed, setItemsRedeemed] = useState(0);
    const [itemsRemaining, setItemsRemaining] = useState(0);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const [payWithSplToken, setPayWithSplToken] = useState(false);
    const [price, setPrice] = useState(0);
    const [priceLabel, setPriceLabel] = useState<string>('SOL');
    const [whitelistPrice, setWhitelistPrice] = useState(0);
    const [whitelistEnabled, setWhitelistEnabled] = useState(false);
    const [isBurnToken, setIsBurnToken] = useState(false);
    const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);
    const [isEnded, setIsEnded] = useState(false);
    const [endDate, setEndDate] = useState<Date>();
    const [isPresale, setIsPresale] = useState(false);
    const [isWLOnly, setIsWLOnly] = useState(false);

    const TeamDefaultObj = {
        title: "Our team",
        description: "We are team of free minded and connected people that want to share the spirit with like minded community of NTF",
    };

    const [TeamDefault, setTeamDefault] = useState(TeamDefaultObj);

    const [PoposhaActive, setPoposhaActive] = useState(false);
    const [NeverwinActive, setNeverwinActive] = useState(false);
    const [DimaActive, setDimaActive] = useState(false);
    const [SonderActive, setSonderActive] = useState(false);
    const [SoftcakeActive, setSoftcakeActive] = useState(false);

    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: '',
        severity: undefined,
    });

    const wallet = useAnchorWallet();
    const [candyMachine, setCandyMachine] = useState<CandyMachine>();

    const rpcUrl = props.rpcHost;

    const refreshCandyMachineState = () => {
        (async () => {
            if (!wallet) return;

            const cndy = await getCandyMachineState(
                wallet as anchor.Wallet,
                props.candyMachineId,
                props.connection,
            );

            setCandyMachine(cndy);
            setItemsAvailable(cndy.state.itemsAvailable);
            setItemsRemaining(cndy.state.itemsRemaining);
            setItemsRedeemed(cndy.state.itemsRedeemed);

            var divider = 1;
            if (decimals) {
                divider = +('1' + new Array(decimals).join('0').slice() + '0');
            }

            // detect if using spl-token to mint
            if (cndy.state.tokenMint) {
                setPayWithSplToken(true);
                // Customize your SPL-TOKEN Label HERE
                // TODO: get spl-token metadata name
                setPriceLabel(splTokenName);
                setPrice(cndy.state.price.toNumber() / divider);
                setWhitelistPrice(cndy.state.price.toNumber() / divider);
            } else {
                setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
                setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
            }

            // fetch whitelist token balance
            if (cndy.state.whitelistMintSettings) {
                setWhitelistEnabled(true);
                setIsBurnToken(cndy.state.whitelistMintSettings.mode.burnEveryTime);
                setIsPresale(cndy.state.whitelistMintSettings.presale);
                setIsWLOnly(!isPresale && cndy.state.whitelistMintSettings.discountPrice === null);

                if (
                    cndy.state.whitelistMintSettings.discountPrice !== null &&
                    cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price
                ) {
                    if (cndy.state.tokenMint) {
                        setWhitelistPrice(
                            cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider,
                        );
                    } else {
                        setWhitelistPrice(
                            cndy.state.whitelistMintSettings.discountPrice?.toNumber() /
                            LAMPORTS_PER_SOL,
                        );
                    }
                }

                let balance = 0;
                try {
                    const tokenBalance = await props.connection.getTokenAccountBalance(
                        (
                            await getAtaForMint(
                                cndy.state.whitelistMintSettings.mint,
                                wallet.publicKey,
                            )
                        )[0],
                    );

                    balance = tokenBalance?.value?.uiAmount || 0;
                } catch (e) {
                    console.error(e);
                    balance = 0;
                }
                setWhitelistTokenBalance(balance);
                setIsActive(isPresale && !isEnded && balance > 0);
            } else {
                setWhitelistEnabled(false);
            }

            // end the mint when date is reached
            if (cndy?.state.endSettings?.endSettingType.date) {
                setEndDate(toDate(cndy.state.endSettings.number));
                if (cndy.state.endSettings.number.toNumber() < new Date().getTime() / 1000) {
                    setIsEnded(true);
                    setIsActive(false);
                }
            }
            // end the mint when amount is reached
            if (cndy?.state.endSettings?.endSettingType.amount) {
                let limit = Math.min(
                    cndy.state.endSettings.number.toNumber(),
                    cndy.state.itemsAvailable,
                );
                setItemsAvailable(limit);
                if (cndy.state.itemsRedeemed < limit) {
                    setItemsRemaining(limit - cndy.state.itemsRedeemed);
                } else {
                    setItemsRemaining(0);
                    cndy.state.isSoldOut = true;
                    setIsEnded(true);
                }
            } else {
                setItemsRemaining(cndy.state.itemsRemaining);
            }

            if (cndy.state.isSoldOut) {
                setIsActive(false);
            }
        })();
    };

    const renderGoLiveDateCounter = ({days, hours, minutes, seconds}: any) => {
        return (
            <div>
                <Card elevation={1}>
                    <h1>{days}</h1>Days
                </Card>
                <Card elevation={1}>
                    <h1>{hours}</h1>
                    Hours
                </Card>
                <Card elevation={1}>
                    <h1>{minutes}</h1>Mins
                </Card>
                <Card elevation={1}>
                    <h1>{seconds}</h1>Secs
                </Card>
            </div>
        );
    };

    function displaySuccess(mintPublicKey: any): void {
        let remaining = itemsRemaining - 1;
        setItemsRemaining(remaining);
        setIsSoldOut(remaining === 0);
        if (isBurnToken && whitelistTokenBalance && whitelistTokenBalance > 0) {
            let balance = whitelistTokenBalance - 1;
            setWhitelistTokenBalance(balance);
            setIsActive(isPresale && !isEnded && balance > 0);
        }
        setItemsRedeemed(itemsRedeemed + 1);
        const solFeesEstimation = 0.012; // approx
        if (!payWithSplToken && balance && balance > 0) {
            setBalance(balance - (whitelistEnabled ? whitelistPrice : price) - solFeesEstimation);
        }
        setSolanaExplorerLink(
            cluster === 'devnet' || cluster === 'testnet'
                ? 'https://solscan.io/token/' + mintPublicKey + '?cluster=' + cluster
                : 'https://solscan.io/token/' + mintPublicKey,
        );
        throwConfetti();
    }

    function throwConfetti(): void {
        confetti({
            particleCount: 400,
            spread: 70,
            origin: {y: 0.6},
        });
    }

    const onMint = async () => {
        try {
            setIsMinting(true);
            if (wallet && candyMachine?.program && wallet.publicKey) {
                const mint = anchor.web3.Keypair.generate();
                const mintTxId = (await mintOneToken(candyMachine, wallet.publicKey, mint))[0];

                let status: any = {err: true};
                if (mintTxId) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintTxId,
                        props.txTimeout,
                        props.connection,
                        'singleGossip',
                        true,
                    );
                }

                if (!status?.err) {
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });

                    // update front-end amounts
                    displaySuccess(mint.publicKey);
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                }
            }
        } catch (error: any) {
            // TODO: blech:
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x138')) {
                } else if (error.message.indexOf('0x137')) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            });
        } finally {
            setIsMinting(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (wallet) {
                const balance = await props.connection.getBalance(wallet.publicKey);
                setBalance(balance / LAMPORTS_PER_SOL);
            }
        })();
    }, [wallet, props.connection]);

    useEffect(refreshCandyMachineState, [
        wallet,
        props.candyMachineId,
        props.connection,
        isEnded,
        isPresale,
    ]);

    const renderMintButton = () => (
        <>
            {!isActive &&
            !isEnded &&
            candyMachine?.state.goLiveDate &&
            (!isWLOnly || whitelistTokenBalance > 0) ? (
                <Countdown
                    date={toDate(candyMachine?.state.goLiveDate)}
                    onMount={({completed}) => completed && setIsActive(!isEnded)}
                    onComplete={() => {
                        setIsActive(!isEnded);
                    }}
                    renderer={renderGoLiveDateCounter}
                />
            ) : !wallet ? (
                <MainMintContainer>MINT</MainMintContainer>
            ) : !isWLOnly || whitelistTokenBalance > 0 ? (
                candyMachine?.state.gatekeeper && wallet.publicKey && wallet.signTransaction ? (
                    <GatewayProvider
                        wallet={{
                            publicKey: wallet.publicKey || new PublicKey(CANDY_MACHINE_PROGRAM),
                            //@ts-ignore
                            signTransaction: wallet.signTransaction,
                        }}
                        // // Replace with following when added
                        // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                        gatekeeperNetwork={candyMachine?.state?.gatekeeper?.gatekeeperNetwork} // This is the ignite (captcha) network
                        /// Don't need this for mainnet
                        clusterUrl={rpcUrl}
                        options={{autoShowModal: false}}
                    >
                        <MintButton
                            candyMachine={candyMachine}
                            isMinting={isMinting}
                            isActive={isActive}
                            isEnded={isEnded}
                            isSoldOut={isSoldOut}
                            onMint={onMint}
                        />
                    </GatewayProvider>
                ) : (
                    <MintButton
                        candyMachine={candyMachine}
                        isMinting={isMinting}
                        isActive={isActive}
                        isEnded={isEnded}
                        isSoldOut={isSoldOut}
                        onMint={onMint}
                    />
                )
            ) : (
                <h1>Mint is private.</h1>
            )}
        </>
    );

    const renderMainButtons = () => (
        <ButtonsContainer>
            <MobileMintButtonContainer>{renderMintButton()}</MobileMintButtonContainer>
            <BottomButtons>
                <ButtonContainer
                    onClick={() => window.open('https://discord.com/invite/7xX9bSdtJn', '_blank')}
                >
                    <Discord/>
                    Discord
                </ButtonContainer>

                <DesktopMintButtonContainer>{renderMintButton()}</DesktopMintButtonContainer>
                <ButtonContainer
                    onClick={() => window.open('https://twitter.com/CossacksCrypto', '_blank')}
                >
                    <Twitter/>
                    Twitter
                </ButtonContainer>
            </BottomButtons>
        </ButtonsContainer>
    );

    const roadmapContent = [
        {
            icon: Stage1,
            done: true,
            title: 'Launch of first Utility: CryptoCossacks',
            stage: 1,
            content: [
                'We will have a relatively limited supply. The Crypto Cossacks will be open to mint for first-come, first-served logic and bring its owners the right to access Sich, a community land name. Each CC holder will be able to mint chips in pre-sales.',
                'Step 2 - Launch of second Utility: Chip for CryptoCossacks',
                'Like the CC, Chip will also be able to mint for all holders. The chip will have a little bit more limited supply. Minting will be first-come, first-served, and you need to be a holder. If a holder gets a chance to mint both, they will participate in a secret raffle',
                'Step 3 - Launch of third Utility: Cossack Token(CT)',
                'The Cossack Token will be developed in a relevant period after the CC part 2 collection is revealed. CT token will be available as a payment method in the CryptoCossack Store, which we are aiming to open in the next phase. Once the CT token is deployed to the blockchain, a pile of $CC tokens will be distributed to CT holders for each CryptoCossack they own. The more CryptoCossacks you own, the more token airdrops you get.',
            ],
        },
        {
            icon: Stage2,
            done: false,
            title: 'Cossacks in 3D',
            stage: 2,
            content: [
                'The first-ever created fully detailed 3D animated CryptoCossack.',
                'Since the first day we started this adventure, all we have wanted is to be able to make CryptoCossack a global brand. To achieve this, we first need a prototype. After finishing the Mint, the first thing we will do is prepare the CryptoCossacks prototype. This prototype will be the first step we have taken to produce CryptoCossacks 3D figures. We have done some homemade work on the prototype, and we look forward to sharing them with you.',
            ],
        },
        {
            icon: Stage3,
            done: false,
            title: 'Community decisions',
            stage: 3,
            content: [
                'The online poll system for holders to vote for further decisions regarding coin pool and other community development.',
                'Since the day we started the project, we have said that our community will decide many things. This decision process will continue after the mint is over. To make this possible, we will develop a voting system in which only CryptoCossacks owners can vote by connecting their wallets.',
            ],
        },
        {
            icon: Stage4,
            done: false,
            title: 'Merch',
            stage: 4,
            content: [
                'Launch of our official merchandise available worldwide',
                'Besides web3, we also want to make CryptoCossacks a global brand in the real world. We will start working on producing CryptoCossacks branded textile products, and all these items will be available in the Nyoki Store globally. Although we want to create our brand for merchandise, we can also think about cooperating with major brands. This decision we will make together when the time comes.',
            ],
        },
        {
            icon: Stage5,
            done: false,
            title: 'Mass production',
            stage: 5,
            content: [
                'Production and marketing of 3D printed CryptoCossacks figures.',
                'Unlike textiles, this is something we have never experienced before. After completing the market research and the experience we have gained so far, we will start mass production of 100 carefully selected CryptoCossacks. These 3D models will be available for purchase in CryptoCossack Store. You will be able to pay with your Cossack Tokens in CryptoCossacks.',
            ],
        },
        {
            icon: Stage6,
            done: false,
            title: 'Collabs and more',
            stage: 6,
            content: [
                'CryprtoCossacks X Artist collaboration',
                'By the time we get to this stage.CryptoCossacks will have become a worldwide brand. Instead of looking for an artist to collaborate with, we will choose which artist to collaborate with, together with the community.',
            ],
        },
    ];
    const [activeRoadMapStage, setActiveRoadMapStage] = useState(roadmapContent[0]);

    const faqContent = [
        {
            title: 'How many Crypto Cossacks are there?',
            content: 'We are proud to boast a diverse collection of 3555 randomly generated NFTs.',
        },
        {
            title: 'Will there be a presale?',
            content: 'You must be signed up to our Whitelist to take part in our Presale. We will be holding our Presale very soon, as our Whitelist spaces are limited, please keep an eye on our Discord channel to receive the newest updates on how to sign up.',
        },
        {
            title: 'When is the launch?',
            content: 'Launch will be on 20th of April 6pm GMT pre-sale 8pm GMT pub-sale.',
        },
        {
            title: 'Who is the owner of the NFT?',
            content: 'Once you own a particular CryptoCossack, 5Hz Team grants you a license to use copy and display the purchased art worldwide, whether for personal or commercial use. Ownership of the NFT is mediated entirely by the Smart Contract and the Solana Network.',
        },
        {
            title: 'How do I mint?',
            content: 'You can connect your Phantom wallet in our website. Phantom can be downloaded from the <a href="https://apps.apple.com/us/app/phantom-solana-wallet/id1598432977" target="_blank" rel="noreferrer">App store</a>, <a href="https://play.google.com/store/apps/details?id=app.phantom&hl=uk&gl=US" target="_blank" rel="noreferrer">Google Play</a>, and <a href="https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa" target="_blank" rel="noreferrer">Chrome Extension</a> on IOS, Android and PC devices respectively.',
        },
        {
            title: 'Who are we?',
            content: 'We are a group of well known in particular circles Ukrainian developers who trying to bring next vibe in to Metaverse.',
        },
        {
            title: 'What is the price?',
            content: '0.5 sol for a whitelist members,1 sol for public sale.',
        },
        {
            title: 'What secondary market?',
            content: 'You can list your NFT on <a href="https://magiceden.io/" target="_blank" rel="noreferrer">Magic Eden</a> and <a href="https://solanart.io/" target="_blank" rel="noreferrer">SolanaArt</a>. We are deciding on <a href="https://opensea.io/" target="_blank" rel="noreferrer">OpenSea(Solana)</a>.',
        },
    ];
    const [activeFaq, setActiveFaq] = useState(-1);
    const [navbar, setNavbar] = useState(false);
    const [navbarChecked, setNavbarChecked] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 66) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    const setScroll = () => {
        setNavbarChecked(!navbarChecked);

        let element = navbarChecked;

        if (element) {
            document.body.classList.remove("scroll-off");
            setNavbarChecked(false);
        } else {
            document.body.classList.add("scroll-off");
            setNavbarChecked(true);
        }
    }

    const hideMenu = () => {
        setNavbarChecked(false);
        document.body.classList.remove("scroll-off");
    }

    useEffect(() => {
        changeBackground()
        window.addEventListener("scroll", changeBackground)
    })


    return (
        <main>
            <MainContainer id="home">
                <nav className={navbar ? "navbar header-scroll" : "navbar"}>
                    <div className="navbar-container container">
                        <input type="checkbox" id="check" checked={navbarChecked} onClick={() => setScroll()}/>
                        <div className="hamburger-lines">
                            <span className="line line1"></span>
                            <span className="line line2"></span>
                        </div>
                        <ul className="menu-items">
                            <li><a onClick={() => hideMenu()} href="#about">About</a></li>
                            <li><a onClick={() => hideMenu()} href="#community">Community</a></li>
                            <li><a onClick={() => hideMenu()} href="#roadmap">Roadmap</a></li>
                            <li><a onClick={() => hideMenu()} href="#faq">Faq</a></li>
                            <li><a onClick={() => hideMenu()} href="#team">Team</a></li>
                            <li><a onClick={() => hideMenu()} href="#partners">Partners</a></li>
                        </ul>
                        <div className="logo">
                            <a onClick={() => hideMenu()} href="#home">
                                <LogoImage src={Logo}/>
                            </a>
                        </div>
                    </div>
                </nav>
                <VideoContainer width={'100%'} autoPlay loop muted>
                    <source src={MainVideoWebm} type="video/webm"/>
                    <source src={MainVideoMp4} type="video/mp4"/>
                </VideoContainer>
                <Border>
                    {renderMainButtons()}
                    <AboutContainer id="about">
                        <AboutTextContainer>
                            <AboutTitle>{'About Crypto Cossacks'}</AboutTitle>
                            <AboutText>
                                {
                                    'Crypto Cossacks are free man in the NFT world.\rThey stand for self-govening communities valuing democracy, \nfreedom and acceptance of any religious, ethnic person that \nshares similar views. Cossacks world can bring together like \nminded people and enthusiasts for future art collections. \nCollections can be fused to pass down the free spirit legacy \nto next generations.'
                                }
                            </AboutText>
                        </AboutTextContainer>
                        <AboutCossacks src={AboutCossacksImage}/>
                    </AboutContainer>
                    <CommunityPassContainer id="community">
                        <CommunityPassBox>
                            <CommunityPassBoxImageContainer src={CommunityPassBoxImage}/>
                            <CommunityPassBoxTextContainer>
                                <CommunityPassTitle>
                                    {
                                        "COSSACKS ARE MORE THAN COLLECTABLE ASSET. IT'S A COMMUNITY PASS"
                                    }
                                </CommunityPassTitle>
                                <CommunityPassText>
                                    {
                                        'Cossacks are known as free people with decentralised government. We share this vision by creating community lead projects like NFT drops in metaverse, stealth drops, events, streetwear, partnership and collabs. Each Cossack is a warrior pass into the community of 3555 crafted 3D art objects with unique warrior features and colors. Rariry is determinated by attributes of each individual cossack.'
                                    }
                                </CommunityPassText>
                            </CommunityPassBoxTextContainer>
                        </CommunityPassBox>
                    </CommunityPassContainer>
                    <RoadMapContainer id="roadmap">
                        <RoadMapHeader>ROADMAP</RoadMapHeader>
                        <RoadMap>
                            <StagesContainer>
                                {roadmapContent.slice(0, 3).map((stage) => (
                                    <StageContainer
                                        onClick={() => setActiveRoadMapStage(stage)}
                                        // isActive={stage.stage === activeRoadMapStage.stage}
                                        active={stage.stage === activeRoadMapStage.stage}
                                    >
                                        <StageIcon
                                            active={stage.stage === activeRoadMapStage.stage}
                                        >
                                            <stage.icon/>
                                        </StageIcon>
                                        <StageTitle
                                            active={stage.stage === activeRoadMapStage.stage}
                                        >{`STAGE  ${stage.stage}`}</StageTitle>
                                        {stage.done ? <StageDone active={stage.stage === activeRoadMapStage.stage}>
                                            <svg viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.81333 10.6419L4.28214 6.21986L0 10.3989L8.81333 19L24 4.17903L19.7179 0L8.81333 10.6419Z"/>
                                            </svg>
                                        </StageDone> : ''}

                                    </StageContainer>
                                ))}
                            </StagesContainer>
                            <ActiveStageContentContainer>
                                {activeRoadMapStage.title && (
                                    <ActiveStageTitle>{activeRoadMapStage.title}</ActiveStageTitle>
                                )}
                                {activeRoadMapStage.content.map((subtitle) => (
                                    <ActiveStageSubtitleContainer>
                                        <ActiveStageSubTitle>{subtitle}</ActiveStageSubTitle>
                                    </ActiveStageSubtitleContainer>
                                ))}
                            </ActiveStageContentContainer>
                            <StagesContainer>
                                {roadmapContent.slice(3, 6).map((stage) => (
                                    <StageContainer
                                        active={stage.stage === activeRoadMapStage.stage}
                                        onClick={() => setActiveRoadMapStage(stage)}
                                    >
                                        <StageIcon
                                            active={stage.stage === activeRoadMapStage.stage}
                                        >
                                            <stage.icon/>
                                        </StageIcon>
                                        <StageTitle
                                            active={stage.stage === activeRoadMapStage.stage}
                                        >{`STAGE  ${stage.stage}`}</StageTitle>
                                    </StageContainer>
                                ))}
                            </StagesContainer>
                        </RoadMap>
                        <RoadMapMobileButtonsContainer>
                            <RoadMapButton
                                disabled={activeRoadMapStage.stage === 1}
                                onClick={() => {
                                    setActiveRoadMapStage(
                                        roadmapContent[activeRoadMapStage.stage - 2],
                                    );
                                }}
                            >
                                <ArrowLeft/>
                            </RoadMapButton>
                            <RoadMapButton
                                disabled={activeRoadMapStage.stage === 6}
                                onClick={() => {
                                    setActiveRoadMapStage(roadmapContent[activeRoadMapStage.stage]);
                                }}
                            >
                                <ArrowRight/>
                            </RoadMapButton>
                        </RoadMapMobileButtonsContainer>
                    </RoadMapContainer>
                    <Marquee
                        pauseOnHover={false}
                        pauseOnClick={true}
                        gradient={false}
                        direction="right"
                        speed={30}
                    >
                        <Image src={CossackGallery} alt="Crypto Cossacks Gallery"/>
                    </Marquee>
                    <Faq id="faq">
                        <FaqHeader>Faq</FaqHeader>
                        <FaqContainer>
                            {faqContent.map((item, index) => (
                                <FaqItem onClick={() => setActiveFaq(index)}>
                                    <FaqLeft>
                                        {index === activeFaq ? (
                                            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="18" width="18" height="18" transform="rotate(90 18 0)"
                                                      fill="#AAFF49"/>
                                                <path d="M9 14L4.66987 6.5L13.3301 6.5L9 14Z" fill="black"/>
                                            </svg>
                                        ) : (
                                            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="18" height="18" fill="#AAFF49"/>
                                                <path d="M14 9L6.5 13.3301L6.5 4.66987L14 9Z" fill="black"/>
                                            </svg>
                                        )}
                                    </FaqLeft>
                                    <FaqRight>
                                        <FaqTitle>
                                            {item.title}
                                        </FaqTitle>
                                        <FaqContent
                                            active={index === activeFaq}
                                        >
                                            <div dangerouslySetInnerHTML={{__html: item.content}}/>
                                        </FaqContent>
                                    </FaqRight>
                                </FaqItem>
                            ))}
                        </FaqContainer>
                    </Faq>
                    <Team id="team">
                        <TeamBox>
                            <TeamMain>
                                <TeamTitle>{TeamDefault.title}</TeamTitle>
                                <TeamDesc>
                                    {TeamDefault.description}
                                </TeamDesc>
                            </TeamMain>
                            <TeamRow>
                                <TeamCol
                                    onMouseEnter={() => {
                                        setDimaActive(true);
                                        setTeamDefault({
                                            title: "3Dima",
                                            description: "We are team of free minded and connected people that want to share the spirit with like minded community of NTF",
                                        });
                                    }}
                                    onMouseLeave={() => {
                                        setDimaActive(false);
                                        setTeamDefault(TeamDefaultObj);
                                    }}
                                >
                                    <TeamImage src={!DimaActive ? Dima : DimaHover} alt="3Dima"/>
                                    <TeamP>
                                        <TeamColName>{'3Dima'}</TeamColName>
                                        <TeamColRole>{'Designer/artist'}</TeamColRole>
                                    </TeamP>
                                </TeamCol>
                                <TeamCol
                                    onMouseEnter={() => {
                                        setNeverwinActive(true);
                                        setTeamDefault({
                                            title: "NeverWin",
                                            description: "We are team of free minded and connected people that want to share the spirit with like minded community of NTF",
                                        });
                                    }}
                                    onMouseLeave={() => {
                                        setNeverwinActive(false);
                                        setTeamDefault(TeamDefaultObj);
                                    }}
                                >
                                    <TeamImage
                                        src={!NeverwinActive ? Neverwin : NeverwinHover}
                                        alt="NeverWin"
                                    />
                                    <TeamP>
                                        <TeamColName>{'NeverWin'}</TeamColName>
                                        <TeamColRole>{'Marketing'}</TeamColRole>
                                    </TeamP>
                                </TeamCol>
                                <TeamCol
                                    onMouseEnter={() => {
                                        setSoftcakeActive(true);
                                        setTeamDefault({
                                            title: "SoftCake",
                                            description: "We are team of free minded and connected people that want to share the spirit with like minded community of NTF",
                                        });
                                    }}
                                    onMouseLeave={() => {
                                        setSoftcakeActive(false);
                                        setTeamDefault(TeamDefaultObj);
                                    }}
                                >
                                    <TeamImage
                                        src={!SoftcakeActive ? Softcake : SoftcakeHover}
                                        alt="SoftCake"
                                    />
                                    <TeamP>
                                        <TeamColName>{'SoftCake'}</TeamColName>
                                        <TeamColRole>{'Writer'}</TeamColRole>
                                    </TeamP>
                                </TeamCol>
                                <TeamCol
                                    onMouseEnter={() => {
                                        setPoposhaActive(true);
                                        setTeamDefault({
                                            title: "Poposha",
                                            description: "We are team of free minded and connected people that want to share the spirit with like minded community of NTF",
                                        });
                                    }}
                                    onMouseLeave={() => {
                                        setPoposhaActive(false);
                                        setTeamDefault(TeamDefaultObj);
                                    }}
                                >
                                    <TeamImage
                                        src={!PoposhaActive ? Poposha : PoposhaHover}
                                        alt="Poposha"
                                    />
                                    <TeamP>
                                        <TeamColName>{'Poposha'}</TeamColName>
                                        <TeamColRole>{'Developer'}</TeamColRole>
                                    </TeamP>
                                </TeamCol>
                                <TeamCol
                                    onMouseEnter={() => {
                                        setSonderActive(true);
                                        setTeamDefault({
                                            title: "Sonder",
                                            description: "We are team of free minded and connected people that want to share the spirit with like minded community of NTF",
                                        });
                                    }}
                                    onMouseLeave={() => {
                                        setSonderActive(false);
                                        setTeamDefault(TeamDefaultObj);
                                    }}
                                >
                                    <TeamImage
                                        src={!SonderActive ? Sonder : SonderHover}
                                        alt="Sonder"
                                    />
                                    <TeamP>
                                        <TeamColName>{'Sonder'}</TeamColName>
                                        <TeamColRole>{'Developer'}</TeamColRole>
                                    </TeamP>
                                </TeamCol>
                            </TeamRow>
                        </TeamBox>
                    </Team>

                    {renderMainButtons()}
                    <Partners id="partners">
                        <PartnersTitle>Partners</PartnersTitle>
                        <PartnersRow>
                            <PartnersCol>
                                <a href="https://magiceden.io/" target="_blank" rel="noreferrer"><MagicEden/></a>
                            </PartnersCol>
                            <PartnersCol>
                                <a href="https://solana.com/" target="_blank" rel="noreferrer"><Solana/></a>
                            </PartnersCol>
                            <PartnersCol>
                                <a href="https://opensea.io/" target="_blank" rel="noreferrer"><OpenSea/></a>
                            </PartnersCol>
                            <PartnersCol>
                                <a href="https://mintyscore.com/" target="_blank" rel="noreferrer"><MintyScore/></a>
                            </PartnersCol>
                        </PartnersRow>

                    </Partners>
                </Border>
            </MainContainer>
            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({...alertState, open: false})}
            >
                <Alert
                    onClose={() => setAlertState({...alertState, open: false})}
                    severity={alertState.severity}
                >
                    {alertState.message}
                </Alert>
            </Snackbar>
        </main>
    );
};

export default Home;
