import styled from 'styled-components';
import {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {CircularProgress} from '@material-ui/core';
import {GatewayStatus, useGateway} from '@civic/solana-gateway-react';
import {CandyMachine} from './candy-machine';

export const CTAButton = styled(Button)`
  width: 100% !important;
  height: 100% !important;
  color: var(--cyber-green) !important;
  font-size: 3rem !important;
  box-shadow: none !important;
  font-family: var(--font-horizon) !important;
  font-weight: bold !important;
  transition: all 300ms ease-out !important;
  background-color: var(--black) !important;
  border-radius: 0 !important;
  position: relative;
  text-align: center !important;
  display: block !important;
  line-height: normal !important;

  :hover {
    background-color: var(--cyber-green) !important;
    color: black !important;
  }
`;

export const MintButton = ({
                               onMint,
                               candyMachine,
                               isMinting,
                               isEnded,
                               isActive,
                               isSoldOut,
                           }: {
    onMint: () => Promise<void>;
    candyMachine: CandyMachine | undefined;
    isMinting: boolean;
    isEnded: boolean;
    isActive: boolean;
    isSoldOut: boolean;
}) => {
    const {requestGatewayToken, gatewayStatus} = useGateway();
    const [clicked, setClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    useEffect(() => {
        setIsVerifying(false);
        if (gatewayStatus === GatewayStatus.COLLECTING_USER_INFORMATION && clicked) {
            // when user approves wallet verification txn
            setIsVerifying(true);
        } else if (gatewayStatus === GatewayStatus.ACTIVE && clicked) {
            console.log('Verified human, now minting...');
            onMint();
            setClicked(false);
        }
    }, [gatewayStatus, clicked, setClicked, onMint]);

    return (
        <CTAButton
            disabled={
                clicked ||
                candyMachine?.state.isSoldOut ||
                isSoldOut ||
                isMinting ||
                isEnded ||
                !isActive ||
                isVerifying
            }
            onClick={async () => {
                if (
                    isActive &&
                    candyMachine?.state.gatekeeper &&
                    gatewayStatus !== GatewayStatus.ACTIVE
                ) {
                    console.log('Requesting gateway token');
                    setClicked(true);
                    await requestGatewayToken();
                } else {
                    console.log('Minting...');
                    await onMint();
                }
            }}
            variant="contained"
        >
            {!candyMachine ? (
                'CONNECTING...'
            ) : candyMachine?.state.isSoldOut || isSoldOut ? (
                'SOLD OUT'
            ) : isActive ? (
                isVerifying ? (
                    'VERIFYING...'
                ) : isMinting || clicked ? (
                    <CircularProgress/>
                ) : (
                    'MINT'
                )
            ) : isEnded ? (
                'ENDED'
            ) : candyMachine?.state.goLiveDate ? (
                'SOON'
            ) : (
                'UNAVAILABLE'
            )}
        </CTAButton>
    );
};
